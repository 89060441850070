import Simplemodal from "../../plugins/simplemodal.js";

const fullScreenMenu = new Simplemodal({
  trigger: "data-fullScreenMenu-trigger",
  clickOutSideClose: true,
  backFixed: false,
});


  

// // メニューボタンのクリックイベントを設定
// fullScreenMenuButtons.forEach(function (fullScreenMenuButton) {
//   fullScreenMenuButton.addEventListener("click", function () {
//     // メニュー本体の要素を取得
//     let menuContent = document.getElementById("js-fullScreenMenu-content");

//     // メニュー本体が表示されているかどうかをチェック
//     let isOpen = menuContent.classList.contains("is-open");

//     // メニュー本体の表示状態と aria-expanded を切り替える
//     if (isOpen) {
//       menuContent.classList.remove("is-open");
//       menuContent.classList.add("is-close");
//       menuContent.setAttribute("aria-expanded", "false");
//       fullScreenMenuButton.classList.remove("is-open");
//       fullScreenMenuButton.classList.add("is-close");
//       fullScreenMenuButton.setAttribute("aria-expanded", "false");
//     } else {
//       menuContent.classList.add("is-open");
//       menuContent.classList.remove("is-close");
//       menuContent.setAttribute("aria-expanded", "true");
//       fullScreenMenuButton.classList.remove("is-close");
//       fullScreenMenuButton.classList.add("is-open");
//       fullScreenMenuButton.setAttribute("aria-expanded", "true");
//     }
//   });
// });







// let fullScreenMenuButtons = document.querySelectorAll(".js-fullScreenMenu-button");

// // アンカーリンクのクリックイベントを設定
// let anchorLinks = document.querySelectorAll(".l-header-nav-item__accordion__summary__list-item__outer");
// anchorLinks.forEach(function (link) {
//   link.addEventListener("click", function () {
//     // メニュー本体を閉じる
//     let menuContent = document.getElementById("js-fullScreenMenu-content");
//     menuContent.classList.remove("is-open");

//     // 全てのメニューボタンの状態もリセット
//     fullScreenMenuButtons.forEach(function (fullScreenMenuButton) {
//       fullScreenMenuButton.classList.remove("is-open");
//     });
//   });
// });

let fullScreenMenuButtons = document.querySelectorAll(".fullScreenMenu-button");

// Function to update aria-expanded and class for all buttons
function closeMenuButtons() {
  fullScreenMenuButtons.forEach(function (button) {
    button.setAttribute("aria-expanded", "false");
    button.classList.add("is-close");
    button.classList.remove("is-open"); // Optional: Remove is-open if it's a potential class
  });
}

// Anchor link click event
let anchorLinks = document.querySelectorAll(
  ".l-header-nav-item__accordion__summary__list-item__outer"
);
anchorLinks.forEach(function (link) {
  link.addEventListener("click", function () {
    // Close the main menu
    let menuContent = document.getElementById("js-fullScreenMenu-content");
    menuContent.classList.remove("is-open");

    // Update all menu buttons
    closeMenuButtons();
  });
});

// Initialize or Update Aria attributes based on current state
document.addEventListener("DOMContentLoaded", function () {
  // Assuming the menu content starts closed
  let menuContent = document.getElementById("js-fullScreenMenu-content");
  if (!menuContent.classList.contains("is-open")) {
    fullScreenMenuButtons.forEach(function (button) {
      button.setAttribute("aria-expanded", "false");
    });
  }
});